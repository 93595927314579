import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d705574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inventory-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!
  const _component_bookingoutform = _resolveComponent("bookingoutform")!
  const _component_bookingredemptionform = _resolveComponent("bookingredemptionform")!
  const _component_supplyinformation = _resolveComponent("supplyinformation")!
  const _component_adjuststock = _resolveComponent("adjuststock")!
  const _component_goodtransfer = _resolveComponent("goodtransfer")!
  const _component_transterCold = _resolveComponent("transterCold")!
  const _component_errorList = _resolveComponent("errorList")!
  const _component_ErpDrawer = _resolveComponent("ErpDrawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErpList, {
      url: "/malicrm/sm/stock/stockList",
      rowId: "id",
      columOptions: _ctx.columnList,
      ref: "tableRef",
      id: "inventoryList",
      onRowClick: _ctx.rowClick,
      selection: true,
      customResult: "pageInfo",
      onSelectionChange: _ctx.handleSelectionChange,
      onGetPageList: _ctx.getPageList
    }, {
      header: _withCtx(() => [
        (_ctx.hasPermission('erp:smStock:exportStock'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "erp-default__btn ml-8",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportFileFn && _ctx.exportFileFn(...args)))
            }, "导出"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:operateOutStore'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "erp-primary__btn ml-8",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.btnOperation(1)))
            }, "预约出库"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:operateRedeem'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "erp-primary__btn ml-8",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.btnOperation(2)))
            }, "预约赎货"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:addData'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "erp-primary__btn ml-8",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.btnOperation(3)))
            }, "补充资料"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:changeStock'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              class: "erp-primary__btn ml-8",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.btnOperation(4)))
            }, "调整库存"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:changeOwner'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 5,
              class: "erp-primary__btn ml-8",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.btnOperation(5)))
            }, "转移货权"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:changeStorage'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 6,
              class: "erp-primary__btn ml-8",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.btnOperation(6)))
            }, "移库"))
          : _createCommentVNode("", true),
        (_ctx.hasPermission('erp:smStock:changeStorageLog'))
          ? (_openBlock(), _createElementBlock("div", {
              key: 7,
              class: "erp-primary__btn ml-8",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.btnOperation(7)))
            }, "异常记录"))
          : _createCommentVNode("", true)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, "合计入库件数：" + _toDisplayString(_ctx.pageTotalObj.instoreCountDesc), 1),
          _createElementVNode("span", null, "合计入库重量：" + _toDisplayString(_ctx.pageTotalObj.instoreWeightDesc), 1),
          _createElementVNode("span", null, "合计剩余件数：" + _toDisplayString(_ctx.pageTotalObj.leftCountDesc), 1),
          _createElementVNode("span", null, "合计剩余重量：" + _toDisplayString(_ctx.pageTotalObj.leftWeightDesc), 1)
        ])
      ]),
      _: 1
    }, 8, ["columOptions", "onRowClick", "onSelectionChange", "onGetPageList"]),
    _createVNode(_component_ErpDrawer, {
      visible: _ctx.dialog.visible,
      onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.dialog.visible = false)),
      erp: "",
      class: "fix-top-header"
    }, {
      default: _withCtx(() => [
        (_ctx.formType === 1)
          ? (_openBlock(), _createBlock(_component_bookingoutform, {
              key: 0,
              formType: 1,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 2)
          ? (_openBlock(), _createBlock(_component_bookingredemptionform, {
              key: 1,
              formType: _ctx.formType,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["formType", "editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 3)
          ? (_openBlock(), _createBlock(_component_supplyinformation, {
              key: 2,
              formType: _ctx.formType,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["formType", "editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 4)
          ? (_openBlock(), _createBlock(_component_adjuststock, {
              key: 3,
              formType: _ctx.formType,
              editData: _ctx.editStockData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["formType", "editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 5)
          ? (_openBlock(), _createBlock(_component_goodtransfer, {
              key: 4,
              formType: _ctx.formType,
              editData: _ctx.editStockData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["formType", "editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 6)
          ? (_openBlock(), _createBlock(_component_transterCold, {
              key: 5,
              formType: _ctx.formType,
              editData: _ctx.editData,
              onSuccess: _ctx.successCallback,
              onCancel: _ctx.cancel
            }, null, 8, ["formType", "editData", "onSuccess", "onCancel"]))
          : _createCommentVNode("", true),
        (_ctx.formType === 7)
          ? (_openBlock(), _createBlock(_component_errorList, {
              key: 6,
              onCancel: _ctx.cancel
            }, null, 8, ["onCancel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}