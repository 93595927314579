import { ErpTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 143,
      value: '',
      type: 'input',
    },
    {
      label: '类型',
      prop: 'category',
      propDesc: 'categoryDesc',
      minWidth: 143,
      value: '',
      type: 'input',
      isFilter: false,
    },
    {
      label: '是否出库',
      prop: 'outStoreStatus',
      propDesc: 'outStoreStatusDesc',
      minWidth: 143,
      value: '',
      type: 'input',
      isFilter: false,
    },
    {
      label: '入库日期',
      prop: 'deliverDate', // 属性
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['deliverDateBegin', 'deliverDateEnd'],
    },
    {
      label: '冷库',
      prop: 'storageName',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '货主',
      prop: 'ownerName',
      width: 100,
      value: '',
      type: 'input',
    },
    {
      label: '柜号',
      prop: 'containerNo',
      width: 125,
      value: '',
      type: 'input',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryName',
      width: 100,
      value: '',
      type: 'input',
    },
    {
      label: '厂号',
      prop: 'plantNo',
      width: 100,
      value: '',
      type: 'input',
    },
    {
      label: '品名',
      prop: 'nameCn',
      minWidth: 150,
      value: '',
      type: 'input',
    },
    {
      label: '入库件数',
      prop: 'instoreCount',

      minWidth: 128,
      value: [],
      type: 'number',
      filterProp: ['instoreCountMin', 'instoreCountMax'],
    },
    {
      label: '入库重量(kg)',
      prop: 'instoreWeight',

      minWidth: 164,
      value: [],
      type: 'number',
      filterProp: ['instoreWeightMin', 'instoreWeightMax'],
    },
    {
      label: '剩余件数',
      prop: 'leftCount',

      minWidth: 140,
      value: [],
      type: 'number',
      filterProp: ['leftCountMin', 'leftCountMax'],
    },
    {
      label: '剩余重量(kg)',
      prop: 'leftWeight',

      minWidth: 154,
      value: [],
      type: 'number',
      filterProp: ['leftWeightMin', 'leftWeightMax'],
    },
    {
      label: '单价',
      prop: 'unitPrice',
      width: 100,
      value: [],
      type: 'number', // 筛选类型 time 日期
      filterProp: ['unitPriceMin', 'unitPriceMax'],
    },
    {
      label: '口岸',
      prop: 'portName',
      propDesc: 'portName',
      width: 100,
      value: '',
      type: 'input', // 筛选类型 time 日期
    },
    {
      label: '检验类型',
      prop: 'inspectModes',
      propDesc: 'inspectModeName',
      minWidth: 128,
      value: [],
      type: 'multiSelect',
      options: 'inspect_mode',
    },
    {
      label: '放行日期',
      prop: 'releaseDate',
      minWidth: 128,
      value: [],
      type: 'time',
      filterProp: ['releaseDateBegin', 'releaseDateEnd'],
    },
    {
      label: '收款状态',
      prop: 'infundStatus',
      propDesc: 'infundStatusName',
      minWidth: 128,
      value: [],
      type: 'multiSelect',
      options: 'infund_status',
    },
    {
      label: '生产日期',
      prop: 'produceDate',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 100,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input',
    },

    {
      label: '报检号',
      prop: 'inspectionNo',
      minWidth: 124,
      value: '',
      type: 'input',
    },
    {
      label: '报关单号',
      prop: 'declarationNo',
      minWidth: 127,
      value: '',
      type: 'input',
    },
    {
      label: '送货问题',
      prop: 'deliverIssue',
      minWidth: 127,
      value: '',
      type: 'input',
    },
    {
      label: '入库单号',
      prop: 'instoreReceiptNo',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 211,
      value: '',
      type: 'input',
    },
  ];
  return { columnList, tableRef };
};
