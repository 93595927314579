<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">转移货权</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">确定</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <div class="transfer-title-warp">
        <div class="transfer-title">转移后的货主</div>
        <div style="display: flex">
          <el-select
            v-model="formData.ownerTypeAfter"
            placeholder="请选择"
            @change="changeOwnerType"
            class="select_goods"
            style="width: 100px; margin-right: 11px"
            size="small"
          >
            <el-option v-for="item in writtenOffList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select v-model="formData.ownerNameAfter" placeholder style="margin-left: 5px" :remote-method="getOwnerNameList" filterable remote size="small">
            <el-option v-for="item in ownerNameList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </div>
      </div>
      <el-table :data="ownerLogList" border class="egrid erp-table__list erp-view__table">
        <el-table-column label="客户" prop="customerName" class-name="no-pd" align="center" show-overflow-tooltip width="240">
          <template #default="scope">
            <div>{{ filterName(scope.row.customerName) }}</div>
          </template>
        </el-table-column>
        <el-table-column width="210" label="销售合同号" prop="businessContractNo" align="center" class-name="no-pd" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.businessContractNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="国家" prop="countryName" class-name="no-pd" align="center" show-overflow-tooltip width="130">
          <template #default="scope">
            <div>{{ filterName(scope.row.countryName) }}</div>
          </template>
        </el-table-column>

        <el-table-column label="品名" prop="nameCn" class-name="no-pd" align="center" width="254" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.nameCn) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="货主" prop="ownerName" class-name="no-pd" align="center" show-overflow-tooltip>
          <template #default="scope">
            <div>{{ filterName(scope.row.ownerName) }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object, Array],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      ownerNameList: [],
      isshowpage: false,
      writtenOffList: [
        {
          value: 1,
          label: '资金方',
        },
        {
          value: 2,
          label: '抬头',
        },
        {
          value: 3,
          label: '客户',
        },
      ],
      isshow: false,
      activeName: '1',
      ownerLogList: [], // 货主转移记录

      formData: {
        changeOwnerDtos: [
          {
            businessContractNo: 'string',
            id: 0,
            version: 0,
          },
        ],
        ownerNameAfter: null,
        ownerTypeAfter: null,
      },
    };
  },

  created() {
    this.ownerLogList = JSON.parse(JSON.stringify(this.editData));
    if (!this.ownerLogList[0].isNeedJr) {
      const obj = [
        {
          value: 2,
          label: '抬头',
        },
        {
          value: 3,
          label: '客户',
        },
      ];
      this.writtenOffList = obj;
    }
  },
  methods: {
    changeOwnerType() {
      this.ownerNameAfter = null;
      this.ownerNameList = [];
      this.getOwnerNameList('');
    },

    getOwnerNameList(name) {
      const url = [null, '/malicrm/funding/queryByShortName', '/malicrm/consignee/queryByName', '/malicrm/offer/queryCustomerByName']; // 根据下标来整合url 这样可以避免写过多的判断
      const type = this.formData.ownerTypeAfter;
      const data = [null, { fundingShortName: name }, { portName: name }, { customerName: name }];
      if (type) {
        this.ajax({
          type: 'POST',
          url: url[type],
          data: data[type],
          loading: true,
          success: (res) => {
            if (res.result && res.result.length > 0) {
              const data = [];
              res.result.forEach((v) => {
                if (type === 1 || type === 2) {
                  const item = { name: v.shortName, id: v.id };
                  data.push(item);
                }
                if (type === 3) {
                  const item = { name: v.customerName, id: v.id };
                  data.push(item);
                }
              });
              this.ownerNameList = data;
            } else {
              this.ownerNameList = [];
            }
          },
        });
      }
    },
    getOwnerLogList(id) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/changeOwnerLog',
        data: {
          stockProductId: id,
        },
        loading: true,
        success: (res) => {
          this.ownerLogList = res.result.list;
        },
      });
    },
    back() {
      this.$emit('cancel', false);
    },
    /**
     * 提交事件
     */
    submit() {
      this.saveSubmitForm();
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = [];

      this.editData.forEach((v) => {
        const obj = {
          businessContractNo: v.businessContractNo,
          id: v.stockProductId,
          version: v.productVersion,
        };
        params.push(obj);
      });
      this.formData.changeOwnerDtos = params;
      if (!this.formData.ownerNameAfter || !this.formData.ownerTypeAfter) {
        this.errorTip('请填写所有带星号的必填项');
        return false;
      }
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/changeOwner',
        data: this.formData,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            // this.getOwnerLogList(this.editData.id);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.adjust_tips_title {
  color: #f04737;
  font-size: 14px;

  margin-top: 14px;
}

.adjust_add_img {
  position: relative;
  top: 3px;
  width: 17px;
  height: 17px;
}
.transfer-title {
  font-size: 14px;
  color: #303133;
  margin-bottom: 10px;
  &::after {
    content: '*';
    color: #f04737;
    font-size: 14px;
    position: relative;
    left: 5px;
  }
}
.transfer-title-warp {
  padding: 0 16px 30px 16px;
  margin-top: 24px;
}
</style>
