<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">移库</div>
      <div class="erp-form-bar">
        <el-button
          class="erp-primary__btn"
          size="small"
          type="primary"
          @click="submit"
          >确定</el-button
        >
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body" style="margin-top: 8px">
      <el-table
        :data="formData.productDtos"
        border
        class="egrid erp-table__list erp-view__table"
      >
        <el-table-column
          label="客户"
          prop="customerName"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="180"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.customerName) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="销售合同号"
          prop="businessContractNo"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="160"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.businessContractNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="国家"
          prop="countryName"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="100"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.countryName) }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="品名"
          prop="nameCn"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.nameCn) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="现存冷库"
          prop="storageName"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="120"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.storageName) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          label="转移后的冷库"
          prop="leftCountAfter"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
        >
          <template v-slot:header>
            <span class="erp-req">转移后的冷库</span>
          </template>
          <template #default="scope">
            <storageSelect
              @change="getChangeStrage($event, scope)"
            ></storageSelect>
          </template>
        </el-table-column>
        <el-table-column
          width="130"
          label="转移后的柜号"
          prop="containerNoAfter"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
        >
          <template v-slot:header>
            <span class="erp-req">转移后的柜号</span>
          </template>
          <template #default="scope">
            <el-input
              v-model="scope.row.containerNoAfter"
              placeholder
              :maxlength="11"
              clearable
              @blur="blurContainerNo($event, scope.$index, 'containerNoAfter')"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div></div>
  </div>
</template>

<script>
import storageSelect from './components/storageSelect';
export default {
  components: {
    storageSelect,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      activeName: '1',
      stockLogList: [],
      isshowpage: false,
      formData: [],
      dataQueryForm: {
        stockProductId: this.editData.id,
        pageNum: 0,
        pageSize: 10,
      },
    };
  },
  created() {
    this.formData = JSON.parse(JSON.stringify(this.editData));
    this.getStockLogList();
  },
  methods: {
    getChangeStrage(e, scope) {
      const { $index } = scope;
      if (e && e.id) {
        this.formData.productDtos[$index].storageNameAfter = e.shortName;
        this.formData.productDtos[$index].storageIdAfter = e.id;
      } else {
        this.formData.productDtos[$index].storageNameAfter = null;
        this.formData.productDtos[$index].storageIdAfter = null;
      }
    },
    back() {
      this.$emit('cancel', true);
    },
    queryCallBackFn(data) {
      this.stockLogList = data.result.list;
      this.isshowpage = data.result.pages > 1;
      this.dataQueryForm.pageNum = data.nextPage;
    },
    getStockLogList() {
      this.ajax({
        type: 'POST',
        url: 'malicrm/sm/stock/changeStorageLog',
        data: {},
        loading: true,
        success: (res) => {
          this.stockLogList = res.result.list;
        },
      });
    },
    /**
     * 提交事件
     */
    submit() {
      this.saveSubmitForm();
    },
    // 柜号blur事件
    blurContainerNo(e, index, name) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除“数字和字母以外的字符
      this.formData.productDtos[index][name] = value;
    },
    validateFrom() {
      const data = this.formData.productDtos;
      const status = true;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (!item.storageIdAfter || !item.containerNoAfter) {
          this.errorTip('请填写所有带星号的必填项');
          return false;
        }
        if (
          item.containerNoAfter &&
          !item.containerNoAfter.match(/^[a-zA-Z]{4}[0-9]{7}$/)
        ) {
          this.errorTip('转移后的柜号需固定11个字符,4位英文+7位数字');
          return false;
        }
      }
      return status;
    },
    saveSubmitForm() {
      // 保存或编辑表单
      if (this.validateFrom()) {
        const params = this.formData.productDtos;
        const paramsDto = [];
        params.forEach((v) => {
          const obj = {
            id: v.stockProductId,
            storageIdAfter: v.storageIdAfter,
            storageNameAfter: v.storageNameAfter,
            containerNoBefore: v.containerNo,
            containerNoAfter: v.containerNoAfter,
            stockId: v.stockId,
            orderId: v.orderId,
            version: v.productVersion,
          };
          paramsDto.push(obj);
        });

        this.ajax({
          type: 'POST',
          url: '/malicrm/sm/stock/changeStorage',
          data: {
            changeStorageDtoList: paramsDto,
          },
          success: (res) => {
            if (res.code === 200) {
              this.successTip('修改成功');
              this.getStockLogList(this.editData.id);
              this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
            } else {
              this.errorTip(res.message);
            }
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.adjust_tips_title {
  color: #f04737;
  font-size: 14px;
  margin-left: 23px;
  margin-top: 14px;
}

.adjust_add_img {
  position: relative;
  top: 3px;
  width: 17px;
  height: 17px;
}
</style>
