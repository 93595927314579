<template>
  <div>
    <el-tabs
      style="margin-top: 0px"
      v-model="activeName"
      class="order-tabs error-tabs"
    >
      <el-tab-pane label="调整库存记录" name="1">
        <div class="erp-form__header" style="margin-top: 28px">
          <div class="erp-form__title"></div>
          <div class="erp-form-bar op-16">
            <div class="erp-default__btn" @click="back">返回</div>
          </div>
        </div>
        <div class="erp-form__body">
          <el-row :gutter="16" type="flex">
            <el-col :span="24">
              <el-table
                :data="adjuststockList"
                border
                class="egrid erp-table__list erp-view__table"
              >
                <el-table-column
                  label="客户"
                  prop="customerName"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="160"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.customerName) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="销售合同号"
                  prop="businessContractNo"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.businessContractNo) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="国家"
                  prop="countryName"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.countryName) }}</div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="品名"
                  prop="nameCn"
                  class-name="no-pd"
                  align="center"
                  width="150"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.nameCn) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="剩余件数"
                  prop="leftCountBefore"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="90"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.leftCountBefore) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="调整后的剩余件数"
                  prop="leftCountAfter"
                  align="center"
                  class-name="no-pd importnice_nadhei"
                  show-overflow-tooltip
                  width="80"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.leftCountAfter) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建人"
                  prop="creator"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="90"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.creator) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建时间"
                  prop="createdTime"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="150"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.createdTime) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <pc-pagination
            class="ad-page"
            v-show="isshowpage1"
            ref="pcPagination"
            url="malicrm/sm/stock/changeStockLog"
            :queryData="dataQueryForm1"
            @change="queryCallBackFn1"
            :isSimple="false"
          >
          </pc-pagination>
        </div>
        <div></div>
      </el-tab-pane>
      <el-tab-pane label="转移货权记录" name="2">
        <div class="erp-form__header" style="margin-top: 28px">
          <div class="erp-form__title"></div>
          <div class="erp-form-bar op-16">
            <div class="erp-default__btn" @click="back">返回</div>
          </div>
        </div>
        <div class="erp-form__body">
          <el-row :gutter="16" type="flex">
            <el-col :span="24">
              <el-table
                :data="ownerLogList"
                border
                class="egrid erp-table__list erp-view__table"
              >
                <el-table-column
                  label="客户"
                  prop="customerName"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="160"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.customerName) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="销售合同号"
                  prop="businessContractNo"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="180"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.businessContractNo) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="国家"
                  prop="countryName"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.countryName) }}</div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="品名"
                  prop="nameCn"
                  class-name="no-pd"
                  align="center"
                  width="150"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.nameCn) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="转移前的货主"
                  prop="ownerNameBefore"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="120"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.ownerNameBefore) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="转移后的货主"
                  prop="ownerNameAfter"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="120"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.ownerNameAfter) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建人"
                  prop="creator"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="90"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.creator) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建时间"
                  prop="createdTime"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="150"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.createdTime) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <pc-pagination
            class="ad-page"
            v-show="isshowpage2"
            ref="pcPagination"
            url="/malicrm/sm/stock/changeOwnerLog"
            :queryData="dataQueryForm2"
            @change="queryCallBackFn2"
            :isSimple="false"
          >
          </pc-pagination>
        </div>
        <div></div>
      </el-tab-pane>

      <el-tab-pane label="移库记录" name="3" lazy>
        <div class="erp-form__header" style="margin-top: 28px">
          <div class="erp-form__title"></div>
          <div class="erp-form-bar op-16">
            <div class="erp-default__btn" @click="back">返回</div>
          </div>
        </div>
        <div class="erp-form__body">
          <el-row :gutter="16" type="flex">
            <el-col :span="24">
              <el-table
                :data="transterColdList"
                border
                class="egrid erp-table__list erp-view__table"
              >
                <el-table-column
                  label="客户"
                  prop="customerName"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="156"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.customerName) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="销售合同号"
                  prop="businessContractNo"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="150"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.businessContractNo) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="国家"
                  prop="countryName"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="100"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.countryName) }}</div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="品名"
                  prop="nameCn"
                  class-name="no-pd"
                  align="center"
                  width="150"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.nameCn) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="原冷库"
                  prop="storageNameBefore"
                  class-name="no-pd"
                  align="center"
                  show-overflow-tooltip
                  width="120"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.storageNameBefore) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="转移后的冷库"
                  prop="storageNameAfter"
                  align="center"
                  class-name="no-pd "
                  show-overflow-tooltip
                  width="120"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.storageNameAfter) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="原冷库柜号"
                  prop="containerNoBefore"
                  align="center"
                  class-name="no-pd "
                  show-overflow-tooltip
                  width="120"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.containerNoBefore) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="转移后的柜号"
                  prop="containerNoAfter"
                  align="center"
                  class-name="no-pd "
                  show-overflow-tooltip
                  width="120"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.containerNoAfter) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建人"
                  prop="creator"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="90"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.creator) }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建时间"
                  prop="createdTime"
                  align="center"
                  class-name="no-pd"
                  show-overflow-tooltip
                  width="168"
                >
                  <template #default="scope">
                    <div>{{ filterName(scope.row.createdTime) }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <pc-pagination
            class="ad-page"
            v-show="isshowpage3"
            ref="pcPagination"
            url="/malicrm/sm/stock/changeStorageLog"
            :queryData="dataQueryForm3"
            @change="queryCallBackFn3"
            :isSimple="false"
          >
          </pc-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PcPagination from '@/components/Pagination/Pagination.vue';

export default {
  components: {
    PcPagination,
  },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      activeName: '1',
      ownerLogList: [],
      adjuststockList: [],
      transterColdList: [],
      isshowpage1: false,
      isshowpage2: false,
      isshowpage3: false,
      formData: [],
      dataQueryForm1: {
        pageNum: 1,
        pageSize: 10,
      },
      dataQueryForm2: {
        pageNum: 1,
        pageSize: 10,
      },
      dataQueryForm3: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },

  methods: {
    back() {
      this.$emit('cancel', true);
    },
    queryCallBackFn2(data, total) {
      this.ownerLogList = data;
      this.isshowpage1 = total > 10;
      this.dataQueryForm1.pageNum = data.nextPage;
    },
    queryCallBackFn1(data, total) {
      this.adjuststockList = data;
      this.isshowpage2 = total > 10;
      this.dataQueryForm2.pageNum = data.nextPage;
    },
    queryCallBackFn3(data, total) {
      this.transterColdList = data;
      this.isshowpage3 = total > 10;
      this.dataQueryForm3.pageNum = data.nextPage;
    },
  },
};
</script>

<style lang="scss" scoped>
.adjust_tips_title {
  color: #f04737;
  font-size: 14px;
  margin-left: 23px;
  margin-top: 14px;
}

.adjust_add_img {
  position: relative;
  top: 3px;
  width: 17px;
  height: 17px;
}
.ad-page {
  margin: 10px 0;
  text-align: right;
}
</style>
