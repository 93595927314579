<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">调整库存</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">确定</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-table
        :data="formData"
        border
        class="egrid erp-table__list erp-view__table"
      >
        <el-table-column
          label="客户"
          prop="customerName"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="210"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.customerName) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="销售合同号"
          prop="businessContractNo"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="235"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.businessContractNo) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="国家"
          prop="countryName"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="110"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.countryName) }}</div>
          </template>
        </el-table-column>

        <el-table-column
          label="品名"
          prop="nameCn"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.nameCn) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="剩余件数"
          prop="leftCount"
          class-name="no-pd"
          align="center"
          show-overflow-tooltip
          width="90"
        >
          <template #default="scope">
            <div>{{ filterName(scope.row.leftCount) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="调整后的剩余件数"
          prop="leftCountAfter"
          align="center"
          class-name="no-pd importnice_naddd"
          show-overflow-tooltip
          width="160"
        >
          <template #default="scope">
            <el-input
              v-model="scope.row.leftCountAfter"
              placeholder
              :maxlength="4"
              class="confirmaadd"
              @blur="changeCount($event, 'leftCountAfter', scope)"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div class="adjust_tips_title">
        提示：强行调整库存会造成入库记录和出库记录件数的不匹配，所以请谨慎使用本功能。
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      activeName: '1',
      stockLogList: [],
      isshowpage: false,
      formData: [],
      dataQueryForm: {
        stockProductId: this.editData.id,
        pageNum: 0,
        pageSize: 10,
      },
    };
  },

  created() {
    this.formData = [JSON.parse(JSON.stringify(this.editData))];
    // this.getStockLogList(this.editData.id);
  },
  methods: {
    changeCount(e, name, scope) {
      let value = e.target.value;
      const index = scope.$index;
      value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符
      this.$nextTick(() => {
        if (value === '0') {
          value = null;
        } else {
          value = value ? Number(value) : null;
        }
        this.formData[index][name] = value;
      });
    },
    back() {
      this.$emit('cancel', true);
    },
    queryCallBackFn(data) {
      this.stockLogList = data.result.list;
      this.isshowpage = data.result.pages > 1;
      this.dataQueryForm.pageNum = data.nextPage;
    },
    getStockLogList(id) {
      this.ajax({
        type: 'POST',
        url: 'malicrm/sm/stock/changeStockLog',
        data: {
          stockProductId: id,
        },
        loading: true,
        success: (res) => {
          this.stockLogList = res.result.list;
        },
      });
    },
    /**
     * 提交事件
     */
    submit() {
      this.saveSubmitForm();
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData))[0];
      if (!params.leftCountAfter) {
        this.errorTip('请填写所有带星号的必填项');
        return false;
      }
      // 更换版本号
      params.version = this.editData.version;
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/changeStock',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('修改成功');
            this.getStockLogList(this.editData.id);
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.$emit('success', true);
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.adjust_tips_title {
  color: #f04737;
  font-size: 14px;
  margin-left: 23px;
  margin-top: 14px;
}

.adjust_add_img {
  position: relative;
  top: 3px;
  width: 17px;
  height: 17px;
}
</style>
