<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">补充资料</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form
        ref="form"
        label-position="top"
        :show-message="true"
        :rules="rules"
        :model="formData"
      >
        <div class="mali-edit__form" style="paading: 0; width: 100%">
          <el-row :gutter="0">
            <el-col :span="12">
              <el-form-item label="放行日期" prop="releaseDate">
                <el-date-picker
                  v-model="formData.releaseDate"
                  type="date"
                  placeholder="请选择放行日期"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  v-model="formData.remark"
                  placeholder="请输入备注"
                  :maxlength="100"
                  class="mali-full__input11"
                  type="textarea"
                  :autosize="{ minRows: 3 }"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      formData: {
        // 绑定表单数据
        releaseDate: null,
        id: null,
        remark: null,
        version: null,
      },
      rules: {},
    };
  },

  created() {
    this.formData = JSON.parse(JSON.stringify(this.editData));
  },
  methods: {
    back() {
      this.$emit('success', false); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
    },
    /**
     * 提交事件
     */
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveSubmitForm();
        }
      });
    },
    saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.releaseDate = formatTime(params.releaseDate, 'YYYY-MM-DD');
      this.ajax({
        type: 'POST',
        url: '/malicrm/sm/stock/addData',
        data: {
          ...params,
        },
        success: (res) => {
          if (res.code === 200) {
            this.successTip('保存成功');
            this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
          } else {
            this.$emit('success', true);
            this.errorTip(res.message);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
