<template>
  <div>
    <el-select
      v-model="sellStm"
      value-key="id"
      placeholder
      @change="changeSellStm"
      :remote-method="getSellStmList"
      filterable
      remote
      clearable
    >
      <el-option
        v-for="item in sellStmList"
        :key="item.id"
        :label="item.shortName"
        :value="item"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ['value', 'orderId'],
  data() {
    return {
      sellStm: null,
      sellStmList: [],
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.sellStm = JSON.parse(JSON.stringify(this.value));
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getSellStmList('');
  },
  methods: {
    getSellStmList(name) {
      this.ajax({
        type: 'POST',
        url: '/malicrm/storage/queryByFullName',
        data: {
          storageName: name,
        },
        loading: true,
        success: (res) => {
          this.sellStmList = res.result;
        },
      });
    },
    changeSellStm(item) {
      this.$emit('change', item);
    },
  },
};
</script>

<style></style>
