
import { defineComponent, reactive, ref, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';

import { erpConfirm, errorMessage, errorTip, exportFile } from '@/utils/publicMethods';
import useTimer from '@/hooks/useTimer';
import bookingoutform from './bookingoutform.vue';
import bookingredemptionform from './bookingredemptionform.vue';
import supplyinformation from './supplyinformation.vue';
import goodtransfer from './goodtransfer.vue';
import adjuststock from './adjuststock.vue';
import transterCold from './transterCold.vue';
import errorList from './errorList.vue';
export default defineComponent({
  name: 'inventoryList',
  components: {
    ErpList,
    bookingredemptionform,
    bookingoutform,
    supplyinformation,
    goodtransfer,
    adjuststock,
    errorList,
    transterCold,
  },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const { visible, showVisible, closeVisible } = useVisible();
    const { startTimer, timeStatus } = useTimer();
    const data = reactive({
      formType: 1,
      productData: [],
      type: 1,
      editData: {},
      editStockData: {},
    });
    const dialog = reactive({
      visible: false,
    });

    const pageTotalObj = reactive({
      instoreCount: 0,
      instoreCountDesc: '',
      instoreWeight: '',
      instoreWeightDesc: '',
      leftCount: '',
      leftCountDesc: '',
      leftWeight: '',
      leftWeightDesc: '',
    });
    const selRowsArr = ref([]);

    const handleSelectionChange = (val) => {
      selRowsArr.value = val;
    };
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };

    const getPageList = (res) => {
      console.log(res);
      pageTotalObj.instoreCount = res.instoreCount;
      pageTotalObj.instoreCountDesc = res.instoreCountDesc;
      pageTotalObj.instoreWeight = res.instoreWeight;
      pageTotalObj.instoreWeightDesc = res.instoreWeightDesc;
      pageTotalObj.leftCount = res.leftCount;
      pageTotalObj.leftCountDesc = res.leftCountDesc;
      pageTotalObj.leftWeight = res.leftWeight;
      pageTotalObj.leftWeightDesc = res.leftWeightDesc;
    };
    // 返回

    // 导出
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          const parmas = Object.assign({}, tableRef.value.getQueryParams());
          parmas.port = parmas.portName;
          parmas.consignee = parmas.consigneeShortName;
          exportFile({
            type: 'POST',
            url: '/malicrm/sm/stock/exportStock',
            data: parmas,
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    // 查看详情
    const rowClick = (row) => {
      tableRef.value.erpTableRef.table.toggleRowSelection(row);
    };
    const successCallback = (status) => {
      dialog.visible = false;
      if (status) {
        refreshTable(true);
      }
    };
    const cancel = () => {
      dialog.visible = false;
    };

    const filterRepeat = (arr, prop) => {
      return arr.filter(function (element, index, self) {
        return self.findIndex((el) => el[prop] === element[prop]) === index;
      });
    };
    // 预约送货
    const btnOperation = (opertyType) => {
      const selLen = selRowsArr.value.length;
      data.editData = {};
      const editData = data.editData as any;
      // 预约出库
      if (opertyType === 1) {
        if (selLen < 1) {
          errorTip('请选中需要出库的货物');
          return;
        }

        const customentArr = filterRepeat(selRowsArr.value, 'customerId');
        if (customentArr.length > 1) {
          errorTip('请勿选择不同客户的货物');
          return;
        }

        const storageArr = filterRepeat(selRowsArr.value, 'storageId');
        if (storageArr.length > 1) {
          errorTip('请选择相同冷库的货物');
          return;
        }
        // const infundStatusArr = filterRepeat(selRowsArr.value, 'infundStatus');
        // if (infundStatusArr.length > 1) {
        //   errorTip('请勿同时选择已收款和未垫资的货物');
        //   return;
        // }
        // for (let i = 0; i < selRowsArr.value.length; i++) {
        //   if ((selRowsArr.value[i] as any).isOuting) {
        //     errorTip('请勿选择已预约出库的货物');
        //     return;
        //   }
        //   if ((selRowsArr.value[i] as any).isRedeeming) {
        //     errorTip('请勿选择已预约赎货的货物');
        //     return;
        //   }
        // }
        const categoryStatus = filterRepeat(selRowsArr.value, 'category');
        if (categoryStatus.length > 1) {
          errorTip('请选择业务类型相同的货物');
          return;
        }

        (data.editData as any).productDtos = Object.assign([], selRowsArr.value);
        const stockInfo = selRowsArr.value[0] as any;
        editData.customerId = stockInfo.customerId;
        editData.infundStatusName = stockInfo.infundStatusName;
        editData.customerName = stockInfo.customerName;
        editData.storageId = stockInfo.storageId;
        editData.storageName = stockInfo.storageName;
        editData.version = stockInfo.version;
        editData.customerId = stockInfo.customerId;
        editData.category = stockInfo.category;
        editData.infundStatus = stockInfo.infundStatus;
        editData.storageId = stockInfo.storageId;
        editData.productVersion = stockInfo.productVersion;
      } else if (opertyType === 2) {
        // 预约赎货
        if (selLen < 1) {
          errorTip('请选中需要赎回的货物');
          return;
        }
        const customentArr = filterRepeat(selRowsArr.value, 'customerId');
        if (customentArr.length > 1) {
          errorTip('请勿选择不同客户的货物');
          return;
        }

        const storageArr = filterRepeat(selRowsArr.value, 'storageId');
        if (storageArr.length > 1) {
          errorTip('请选择相同冷库的货物');
          return;
        }
        const categoryStatus = filterRepeat(selRowsArr.value, 'category');
        if (categoryStatus.length > 1) {
          errorTip('请选择业务类型相同的货物');
          return;
        }
        for (let i = 0; i < selRowsArr.value.length; i++) {
          const item = selRowsArr.value[i] as any;
          if (item.infundStatus !== 2) {
            errorTip('请选择未收款的货物');
            return;
          }
          // if (item.isOuting) {
          //   errorTip('请勿选择已预约出库的货物');
          //   return;
          // }
          // if (item.isRedeeming) {
          //   errorTip('请勿选择已预约赎货的货物');
          //   return;
          // }
        }
        editData.productDtos = Object.assign(selRowsArr.value);
        const stockInfo = selRowsArr.value[0] as any;
        editData.id = '';
        editData.customerId = stockInfo.customerId;
        editData.customerName = stockInfo.customerName;
        editData.storageId = stockInfo.storageId;
        editData.storageName = stockInfo.storageName;
        editData.version = stockInfo.version;
        editData.productVersion = stockInfo.productVersion;
        data.formType = opertyType;
      } else if (opertyType === 3) {
        // 补充资料
        if (selLen !== 1) {
          errorTip('请选择一条记录');
          return;
        }
        const stockInfo = selRowsArr.value[0] as any;
        editData.id = stockInfo.stockProductId;
        editData.releaseDate = stockInfo.releaseDate;
        editData.remark = stockInfo.remark;
        editData.version = stockInfo.productVersion;
      } else if (opertyType === 4) {
        // 调整库存
        if (selLen !== 1) {
          errorTip('请选择一条记录');
          return;
        }
        if ((selRowsArr.value[0] as any).isOuting) {
          errorTip('请勿选择已预约出库的货物');
          return;
        }
        if ((selRowsArr.value[0] as any).isRedeeming) {
          errorTip('此货物已预约赎货，不可修改');
          return;
        }
        const editStockData = data.editStockData as any;
        const stockInfo = selRowsArr.value[0] as any;
        editStockData.id = stockInfo.stockProductId;
        editStockData.customerName = stockInfo.customerName;
        editStockData.countryName = stockInfo.countryName;
        editStockData.nameCn = stockInfo.nameCn;
        editStockData.leftCount = stockInfo.leftCount;
        editStockData.businessContractNo = stockInfo.businessContractNo;
        editStockData.version = stockInfo.productVersion;
        // editStockData.productVersion = selRowsArr.value[0].productVersion;
      } else if (opertyType === 5) {
        if (selLen <= 0) {
          errorTip('请选择需要转移货权的货物');
          return;
        }
        const selRowsArrs = selRowsArr.value;
        let businessContractNoArrays = selRowsArrs.map((v) => (v as any).businessContractNo);
        businessContractNoArrays = [...new Set(businessContractNoArrays)];
        if (businessContractNoArrays.length > 1) {
          errorTip('请选择同一个合同下的货物');
          return;
        }
        for (let index = 0; index < selRowsArrs.length; index++) {
          const item = selRowsArrs[index] as any;
          if (item.isOuting) {
            errorTip('此货物已预约出库，不可修改');
            return;
          }
          if (item.isRedeeming) {
            errorTip('此货物已预约赎货，不可修改');
            return;
          }
        }
        data.editStockData = JSON.parse(JSON.stringify(selRowsArr.value)) as any;
      } else if (opertyType === 6) {
        // 预约赎货
        if (selLen < 1) {
          errorTip('请选中需要移库的货物');
          return;
        }
        const storageArr = filterRepeat(selRowsArr.value, 'storageId');
        if (storageArr.length > 1) {
          errorTip('选中的货物必须在同一个冷库');
          return;
        }
        for (let i = 0; i < selRowsArr.value.length; i++) {
          const item = selRowsArr.value[i] as any;
          if (item.isOuting) {
            errorTip('此货物已预约出库，不可修改');
            return;
          }
          if (item.isRedeeming) {
            errorTip('此货物已预约赎货，不可修改');
            return;
          }
        }
        editData.productDtos = Object.assign(selRowsArr.value);
        const stockInfo = selRowsArr.value[0] as any;
        editData.id = '';
        editData.customerId = stockInfo.customerId;
        editData.customerName = stockInfo.customerName;
        editData.storageId = stockInfo.storageId;
        editData.storageName = stockInfo.storageName;
        editData.version = stockInfo.version;
        editData.productVersion = stockInfo.productVersion;
        data.formType = opertyType;
      }
      dialog.visible = true;
      data.formType = opertyType;
    };

    return {
      handleSelectionChange,
      getPageList,
      successCallback,
      btnOperation,
      pageTotalObj,
      dialog,
      ...toRefs(data),
      exportFileFn,
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
    };
  },
});
